export const environment = {
  production: false,
  apiUrl: 'https://api.octopus.zdevs.xyz/api/v1',
  firebase: {
    apiKey: "AIzaSyDbO7LPqSFrQhkhKABixfFmlh6ezF270dc",
    authDomain: "octopus-dev-1cc10.firebaseapp.com",
    projectId: "octopus-dev-1cc10",
    storageBucket: "octopus-dev-1cc10.appspot.com",
    messagingSenderId: "657251942585",
    appId: "1:657251942585:web:136a058dcb0ffd2d185b68",
    measurementId: "G-LW5NVXR492"
  }
};
