import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from "@angular/material/card";
import {MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDialogModule} from "@angular/material/dialog";
import {NgxFileDropModule} from "ngx-file-drop";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatInputModule} from "@angular/material/input";
import {JobSettingsComponent} from "./job-settings/job-settings.component";
import {JobComponent} from "./job.component";
import {AddJobDataDialog} from './job-settings/add-job-data-dialog';
import {MatTabsModule} from "@angular/material/tabs";
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {RunComponent} from './run/run.component';
import {
  DistributeRunComponent
} from "./run/distribute/distribute-run/distribute-run.component";
import {RunDetailsComponent} from './run/run-details/run-details.component';
import {ProcessRunComponent} from "./run/process/process-run/process-run.component";
import {SetupRunComponent} from "./run/setup/setup-run/setup-run.component";
import {AddRunDataDialog} from "./run/setup/setup-run/add-run-data-dialog";
import {MatDividerModule} from "@angular/material/divider";
import {NewJobComponent} from './new-job/new-job.component';
import {RouterLinkWithHref} from "@angular/router";
import {JobDetailsComponent} from './job-details/job-details.component';
import {MatSidenavModule} from "@angular/material/sidenav";


@NgModule({
  declarations: [
    JobComponent,
    JobSettingsComponent,
    AddJobDataDialog,
    AddRunDataDialog,
    SetupRunComponent,
    ProcessRunComponent,
    DistributeRunComponent,
    RunComponent,
    RunDetailsComponent,
    NewJobComponent,
    JobDetailsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    NgxFileDropModule,
    FontAwesomeModule,
    MatTabsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDividerModule,
    RouterLinkWithHref,
    MatSidenavModule,
  ],
  exports: [JobComponent,JobSettingsComponent]

})
export class JobModule {
}
