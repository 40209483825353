<form [formGroup]="newJobForm" name="newJobForm">
  <mat-card class="octopus-card">
    <mat-card-title>New Job</mat-card-title>

    <mat-form-field class="form-element">
      <input matInput placeholder="Job Name" formControlName="friendly_name">
    </mat-form-field>

    <br/>

    <button mat-raised-button (click)="createJob()" color="primary">
      CREATE
    </button>

  </mat-card>
</form>
