import {Router} from '@angular/router';
import {Component, OnInit} from "@angular/core";
import {AuthService} from "../../core/_services/auth-service";
import {LoginData} from "../../core/_models/login-data";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router) {
  }

  ngOnInit(): void {
  }

  login(loginData: LoginData) {
    this.authService
    .login(loginData)
    .then(() => this.router.navigate(['/private/job']))
    .catch((e) => console.log(e.message));
  }
}
