<h2 mat-dialog-title>Data File</h2>

<form [formGroup]="addJobDataForm" name="addJobModelForm" novalidate>
  <mat-dialog-content class="mat-typography">

    <mat-form-field appearance="fill">
      <mat-label>Select Job Data File Type</mat-label>
      <mat-select formControlName="jobDataType">
        <mat-option *ngFor="let type of jobDataTypes" [value]="type.value">
          {{type.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ngx-file-drop multiple="false" (onFileDrop)="onFileDrop($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <button class="mt-3 btn btn-primary btn-sm"
                type="button"
                (click)="openFileSelector()">
          <fa-icon [icon]="['fas', 'plus-circle']" [fixedWidth]="true"></fa-icon>
          Upload Data File
        </button>
      </ng-template>
    </ngx-file-drop>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>
      CANCEL
    </button>

    <button mat-raised-button [mat-dialog-close]="true" (click)="addFile()"
            color="primary" cdkFocusInitial>
      ADD
    </button>
  </mat-dialog-actions>
</form>
