<form [formGroup]="addRunForm" name="addRunForm" novalidate>

  <h2>Setup Run</h2>

  <button mat-raised-button color="primary" (click)="addDataFile()">
    ADD DATA FILE
  </button>

  <mat-card class="octopus-card">
    <mat-card-subtitle>Data Files</mat-card-subtitle>

    <div class="table-responsive mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="filename">
          <th mat-header-cell *matHeaderCellDef> Filename</th>
          <td mat-cell *matCellDef="let runData"> {{runData.filename}} </td>
        </ng-container>

        <ng-container matColumnDef="rundatatype">
          <th mat-header-cell *matHeaderCellDef> Type</th>
          <td mat-cell *matCellDef="let runData"> {{runData.rundatatype}} </td>
        </ng-container>

        <ng-container matColumnDef="filesize">
          <th mat-header-cell *matHeaderCellDef> Size</th>
          <td mat-cell *matCellDef="let runData"> {{runData.filesize}} </td>
        </ng-container>

        <ng-container matColumnDef="added_by">
          <th mat-header-cell *matHeaderCellDef> Added By</th>
          <td mat-cell *matCellDef="let runData"> {{runData.added_by}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions</th>
          <td mat-cell *matCellDef="let element;  let j = index;">
            <button mat-icon-button color="warn" matTooltip="Delete Run"
                    (click)="openDialog('Delete', element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card>

  <mat-card class="octopus-card">
    <mat-card-subtitle>Allocate Collectors</mat-card-subtitle>

    <label formArrayName="collectors"
           *ngFor="let item of collectorsFormArray.controls; let i = index">
      <ng-container [formGroupName]="i">
        <mat-checkbox formControlName="isActive">
          {{item.get('name').value}}
        </mat-checkbox>
        <br/>
      </ng-container>
    </label>
  </mat-card>

  <mat-card class="octopus-card">
    <mat-card-subtitle>Transformation File Type</mat-card-subtitle>

    <mat-form-field appearance="fill">
      <mat-label>Select Transformation File Type</mat-label>
      <mat-select formControlName="transformationFileType">
        <mat-option *ngFor="let type of transformationFileTypes" [value]="type.value">
          {{type.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card>

  <button mat-raised-button (click)="actionRun(fetchRunStatus())" color="primary"
          [disabled]="isCreateDisabled()">
    {{fetchRunStatus()}}
  </button>

</form>
