import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";
import {JobService} from 'src/app/core/_services/job-service';
import {NgxFileDropEntry} from "ngx-file-drop";
import {ConfigService} from "../../../core/_services/config-service";


@Component({
  selector: 'app-job-data-dialog',
  templateUrl: './add-job-data-dialog.html',
})
export class AddJobDataDialog implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  private uploadedFile = {};
  jobDataTypes = [];

  addJobDataForm: FormGroup;

  @Output() onSuccess: any;
  @Output() onError: any;

  constructor(private fb: FormBuilder,
              private jobService: JobService,
              private configService: ConfigService,
              private dialogRef: MatDialogRef<AddJobDataDialog>) {
  }

  ngOnInit(): void {
    this.addJobDataForm = this.fb.group(({
      jobDataType: ['', [Validators.required]]
    }))

    this.fetchJobDataTypes();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private fetchJobDataTypes() {
    this.subscriptions.add(
      this.configService.getConfig('jobDataTypes').subscribe(data => {
        data.value.split(',').forEach((type, index) => {
            this.jobDataTypes.push({id: index, value: type})
          }
        );
      })
    );
  }

  public onFileDrop(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.uploadedFile =
            {
              filepath: droppedFile.relativePath,
              file: file
            }
        });
      }
    }
  }

  public addFile() {
    console.log('file: ' + JSON.stringify(this.uploadedFile));

    this.dialogRef.close({
      file: this.uploadedFile['file'],
      filepath: this.uploadedFile['filepath'],
      jobDataType: this.addJobDataForm.controls['jobDataType'].value,
      filesize: this.uploadedFile['file'].size.toString()
    });
  }
}


