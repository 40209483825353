<div class="header">
  <a mat-raised-button color="primary" routerLink="/private/job"
     class="octopus-nav-button">
    Jobs
  </a>

  <a mat-raised-button color="primary" routerLink="/private/model"
     class="octopus-nav-button">
    Models
  </a>

  <a mat-raised-button color="warn" (click)="logout()"
     class="octopus-nav-button">
    Logout
  </a>
</div>
