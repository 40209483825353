import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {ModelService} from "../../../../../core/_services/model-service";
import {ConfigService} from "../../../../../core/_services/config-service";
import {Job} from 'src/app/core/_models/job';
import {JobService} from "../../../../../core/_services/job-service";
import {
  ModelExecutionResponse,
  ModelExecutionResponseFile
} from "../../../../../core/_models/model";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-process-run',
  templateUrl: './process-run.component.html',
  styleUrls: ['./process-run.component.scss']
})
export class ProcessRunComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  private fileContent: Blob;

  @Input() jobId: number;
  @Input() job: Job;

  runId: number;
  processRunForm: FormGroup;
  executionModels = [];
  dataSource = new MatTableDataSource<ModelExecutionResponseFile>();
  displayedColumns: string[] = ['filename', 'filesize', 'date_created'];

  constructor(private fb: FormBuilder,
              private configService: ConfigService,
              private jobService: JobService,
              private modelService: ModelService) {
  }

  ngOnInit(): void {
    this.jobService.currentRunId.subscribe(id => this.runId = id);

    this.processRunForm = this.fb.group(({
      executionModel: ['', [Validators.required]]
    }))

    this.fetchExecutionModels();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  executeModel() {
    const modelId = this.processRunForm.controls['executionModel'].value;
    console.log('model id: ' + modelId);

    this.subscriptions.add(
      this.modelService.executeModel(modelId, this.runId).subscribe(res => {
        console.log('response from service is: ' + JSON.stringify(res));
      })
    );
  }

  private fetchExecutionModels() {
    this.subscriptions.add(
      this.modelService.getModels().subscribe(data => {
        data.forEach(model => {
          this.executionModels.push({id: model.id, value: model.friendly_name})
        });
      })
    );
  }

  checkForResults() {
    const modelId = this.processRunForm.controls['executionModel'].value;
    console.log('model id: ' + modelId);

    this.subscriptions.add(
      this.modelService.getModelResponse(modelId, this.runId).subscribe(res => {
        console.log("res : " + JSON.stringify(res));
        if (res.length > 0) {
          this.extractModelResponse(res);
        } else {
          console.log('nothing yet from the backend - alert user');
        }
      })
    );
  }

  private extractModelResponse(response: ModelExecutionResponse[]) {
    const latest = response.reduce((a, b) => {
      return new Date(a.response_date) > new Date(b.response_date) ? a : b;
    });

    console.log('response: ' + JSON.stringify(latest.parameters["body"]["results"]));
    this.dataSource.data = latest.parameters["body"]["results"];
  }


  downloadFile(result: ModelExecutionResponseFile) {
    console.log('clicked on: ' + JSON.stringify(result));
    const modelId = this.processRunForm.controls['executionModel'].value;

    this.subscriptions.add(
      this.modelService.getModelResponseFile(modelId, this.runId, result.filepath)
      .subscribe(data => {
        this.fileContent = new Blob([data], {type: 'application/octet-stream'});
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = result.filename;
        link.click();
      })
    );

  }
}
