import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {Model} from "../../core/_models/model";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {AddModelFileDialog} from "./add-model-file-dialog";
import {ModelService} from "../../core/_services/model-service";

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss']
})
export class ModelComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  dataSource = new MatTableDataSource<Model>();
  displayedColumns: string[] = [
    'id', 'friendly_name', 'filename', 'filesize', 'added_by', 'date_added', 'actions'
  ];

  public modelForm: FormGroup;

  @Output()
  public valid: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private fb: FormBuilder,
              private modelService: ModelService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.fetchModels();
  }

  get form(): any {
    return this.modelForm.controls;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchModels() {
    this.subscriptions.add(
      this.modelService.getModels().subscribe(data => {
        this.dataSource.data = data;
      })
    );
  }


  openDialog(action, obj) {
    obj.action = action;

    if (obj.action == 'Delete') {
      console.log('delete');
    }
  }

  addModelFile() {
    const dialogRef = this.dialog.open(AddModelFileDialog);
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(result => {
        this.fetchModels();
      }));
  }
}
