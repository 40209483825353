export const STATUS_NEW = "New"
export const STATUS_CREATED = "Created"
export const STATUS_IN_PROGRESS = "In Progress"
export const STATUS_DONE = "Done"
export const STATUS_FAILED = "Failed"

export const ATP = "Atp"
export const MATTER_MONTHLY = "Matter-Monthly"
export const MATTER_DAILY = "Matter-Daily"
export const PAYMENTS = "Payments"
