<mat-card class="octopus-card octopus-main-card">
  <mat-card-title>
    Job <a routerLink="/private/job/{{jobId}}">{{job?.friendly_name}}</a>
  </mat-card-title>

  <mat-card class="octopus-card octopus-main-card">
    <mat-card-title>Job Details</mat-card-title>
    Name: <a routerLink="/private/job/{{jobId}}">{{job?.friendly_name}}</a> <br />
    Status: {{job?.status}} <br />
    Job Date Created: {{job?.date_created | date:'yyyy-MM-dd HH:mm:ss'}} <br />
    Created By: {{job?.created_by}} <br />
    Period: {{job?.parameters["period"]}} <br />
    Job Data Files: <br />

    <label *ngFor="let data of jobData">
      {{data.filename}}
    </label>
  </mat-card>

  <mat-card class="octopus-card octopus-main-card">
    <mat-card-title>Run Details</mat-card-title>

    <div *ngIf="run">
      Status: {{run?.status}} <br />
      Run Date Created: {{run?.date_created | date:'yyyy-MM-dd HH:mm:ss'}} <br />
      Created By: {{run?.created_by}} <br />
    </div>

    <mat-tab-group>
      <mat-tab label="Step 1: Setup">
        <app-setup-run [jobId]="jobId"></app-setup-run>
      </mat-tab>
      <mat-tab label="Step 2: Process">
        <app-process-run [jobId]="jobId"></app-process-run>
      </mat-tab>
<!--
      <mat-tab label="Step 3: Distribute">
        <app-distribute-run></app-distribute-run>
      </mat-tab>
-->
    </mat-tab-group>
  </mat-card>
</mat-card>
