<h2 mat-dialog-title>Model File</h2>

<form [formGroup]="addModelForm" name="addModelForm" novalidate>
  <mat-dialog-content class="mat-typography">

    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input formControlName="friendly_name" matInput placeholder="Ex. Booo"
             value="my-file-001">
    </mat-form-field>

    <ngx-file-drop multiple="false" (onFileDrop)="onFileDrop($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <button class="mt-3 btn btn-primary btn-sm"
                type="button"
                (click)="openFileSelector()">
          <fa-icon [icon]="['fas', 'plus-circle']" [fixedWidth]="true"></fa-icon>
          Upload Model File
        </button>
      </ng-template>
    </ngx-file-drop>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="false" cdkFocusInitial>
      CANCEL
    </button>
    <button mat-raised-button [mat-dialog-close]="true" (click)="addFile()"
            color="primary">
      ADD
    </button>
  </mat-dialog-actions>
</form>
