import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../core/_services/auth-service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router) {
  }

  ngOnInit(): void {
  }

  logout(): void {
    this.authService.logout()
    .then(() => this.router.navigate(['/']))
  }

}
