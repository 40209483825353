import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {from, lastValueFrom, Observable} from 'rxjs';
import {AuthService} from "../_services/auth-service";

@Injectable()
export class FirebaseBearerInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const fireUser = this.authService.auth.currentUser;

    if (fireUser) {
      return from(this.tokenHandler(request, next, fireUser));
    }

    return next.handle(request);
  }

  async tokenHandler(request: HttpRequest<any>, next: HttpHandler, currentUser): Promise<HttpEvent<any>> {
    const authToken = await currentUser.getIdToken();

    if (authToken) {
      request = request.clone({setHeaders: {Authorization: 'Bearer ' + authToken}});
    }

    return lastValueFrom(next.handle(request));
  }
}

