import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModelComponent} from "./model.component";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddModelFileDialog} from "./add-model-file-dialog";
import {MatCardModule} from "@angular/material/card";
import {MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDialogModule} from "@angular/material/dialog";
import {NgxFileDropModule} from "ngx-file-drop";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatInputModule} from "@angular/material/input";


@NgModule({
  declarations: [
    AddModelFileDialog,
    ModelComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    NgxFileDropModule,
    FontAwesomeModule,
  ],
  exports: [ModelComponent]

})
export class ModelModule {
}
