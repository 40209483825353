import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Subscription} from 'rxjs';
import {Run} from "../../../core/_models/run";
import {FormBuilder} from "@angular/forms";
import {JobService} from "../../../core/_services/job-service";
import {ActivatedRoute, Params} from "@angular/router";
import {Job, JobData} from "../../../core/_models/job";

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  jobId: number;
  job: Job;
  jobData: JobData[];

  dataSource = new MatTableDataSource<Run>();
  displayedColumns: string[] = ['id', 'date_created', 'created_by', 'status'];


  constructor(private fb: FormBuilder,
              private jobService: JobService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.jobId = params['job_id'];
      this.fetchRuns();
      this.fetchJob();
      this.fetchJobData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchRuns() {
    this.subscriptions.add(
      this.jobService.getRuns(this.jobId).subscribe(data => {
        console.log('Found ' + data.length + ' jobs.')
        this.dataSource.data = data;
      }));
  }

  fetchJob() {
    this.subscriptions.add(
      this.jobService.getJob(this.jobId).subscribe(res => {
        this.job = res;
      })
    )
  }

  fetchJobData() {
    this.subscriptions.add(
      this.jobService.getJobData(this.jobId).subscribe(res => {
        this.jobData = res;
      })
    )
  }

  runJob() {
    // routerLink="/private/job/{{jobId}}/run"
    // always create a new run and set the run id on the target component (RunComponent)

  }
}
