import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PrivateComponent} from './private.component';
import {ModelComponent} from "./model/model.component";
import {JobComponent} from "./job/job.component";
import {JobSettingsComponent} from "./job/job-settings/job-settings.component";
import {NewJobComponent} from "./job/new-job/new-job.component";
import {JobDetailsComponent} from "./job/job-details/job-details.component";
import {RunComponent} from "./job/run/run.component";
import {RunDetailsComponent} from "./job/run/run-details/run-details.component";

const routes: Routes = [
  {
    path: '',
    component: PrivateComponent,
    data: {breadcrumb: {skip: true}},
    children: [
      {
        path: 'model',
        component: ModelComponent
      },
      {
        path: 'job',
        component: JobComponent
      },
      {
        path: 'job/new',
        component: NewJobComponent,
      },
      {
        path: 'job/:job_id/configure',
        component: JobSettingsComponent
      },
      {
        path: 'job/:job_id',
        component: JobDetailsComponent,
      },
      {
        path: 'job/:job_id/run/:run_id',
        component: RunDetailsComponent
      },
      {
        path: 'job/:job_id/run',
        component: RunComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule {
}
