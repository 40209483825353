<form>
  <mat-card class="octopus-card octopus-main-card">
    <mat-card-title>Jobs</mat-card-title>

    <a mat-raised-button color="primary"
       routerLink="/private/job/new"
       class="octopus-card-anchor-button">
      NEW JOB
    </a>

    <div class="table-responsive mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="friendly_name">
          <th mat-header-cell *matHeaderCellDef> Name</th>
          <td mat-cell *matCellDef="let job">
            <a routerLink="/private/job/{{job.id}}">{{job.friendly_name}}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="date_created">
          <th mat-header-cell *matHeaderCellDef> Date Created</th>
          <td mat-cell *matCellDef="let job">
            {{job.date_created | date:'yyyy-MM-dd HH:mm:ss' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="created_by">
          <th mat-header-cell *matHeaderCellDef> Created By</th>
          <td mat-cell *matCellDef="let job">{{job.created_by}}</td>
        </ng-container>


        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status</th>
          <td mat-cell *matCellDef="let job"> {{job.status}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card>
</form>
