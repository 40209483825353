import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from "rxjs";
import {Model, ModelExecutionResponse} from '../_models/model';


const headers = new HttpHeaders().set('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  private url = environment.apiUrl + '/models';

  constructor(
    private http: HttpClient) {
  }

  createModel(formData: FormData): Observable<Model> {
    return this.http.post<Model>(this.url, formData);
  }

  deleteModel(model: Model): Observable<Model> {
    return this.http.delete<Model>(this.url + '/' + model.id, {headers});
  }

  getModels(): Observable<Model[]> {
    return this.http.get<Model[]>(this.url, {headers});
  }

  executeModel(modelId: number, runId: number): Observable<any> {
    return this.http.post(
      this.url + '/' + modelId + '/execute?run_id=' + runId,
      {headers}
    );
  }

  getModelResponse(modelId: number, runId: number): Observable<ModelExecutionResponse[]> {
    return this.http.get<ModelExecutionResponse[]>(
      this.url + '/' + modelId + '/response?run_id=' + runId,
      {headers}
    );
  }

  getModelResponseFile(modelId: number, runId: number, filepath: string): Observable<any> {
    return this.http.get(
      this.url + '/' + modelId + '/response/download?run_id=' + runId + '&filepath=' + filepath,
      {responseType: 'blob' as 'json', headers}
    );
  }


}
