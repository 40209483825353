import {Auth, signInWithEmailAndPassword, signOut} from '@angular/fire/auth';

import {Injectable} from '@angular/core';
import {LoginData} from '../_models/login-data';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(public auth: Auth) {
  }

  login({email, password}: LoginData) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  logout() {
    return signOut(this.auth);
  }
}
