export const MODEL_TYPE_ETL = "etl"
export const MODEL_TYPE_BPMN = "bpmn"
export const MODEL_TYPE_DMN = "dmn"

export interface Model {

  id: number;
  friendly_name: string;
  modeltype: string;
  filename: string;
  filesize: number;
  date_added: string;
  added_by: number;

}

export interface ModelExecutionResponse {
  id: number;
  response_date: string;
  model_id: number;
  run_id: number;
  parameters: object;
}

export interface ModelExecutionResponseFile {
  filename: string;
  filepath: string;
  filesize: number;
  date_created: string;

}
