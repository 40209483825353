import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PrivateComponent} from './private.component';
import {PrivateRoutingModule} from './private-routing.module';
import {HeaderComponent} from './header/header.component';
import {ModelModule} from "./model/model.module";
import {JobModule} from './job/job.module';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import { FirebaseBearerInterceptor } from '../core/_interceptors/firebase-bearer.interceptor';
import {HTTP_INTERCEPTORS} from "@angular/common/http";

@NgModule({
  declarations: [
    PrivateComponent,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    PrivateRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ModelModule,
    JobModule,
    MatToolbarModule,
    MatButtonModule,
  ],
  exports: [PrivateComponent],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: FirebaseBearerInterceptor, multi: true}
  ],
})
export class PrivateModule {
}
