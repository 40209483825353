<form>
  <mat-card class="octopus-card octopus-main-card">
    <mat-card-title>Models</mat-card-title>

    <button mat-raised-button color="primary" (click)="addModelFile()"
            class="octopus-card-anchor-button">
      ADD MODEL FILE
    </button>

    <div class="table-responsive mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID</th>
          <td mat-cell *matCellDef="let model"> {{model.id}} </td>
        </ng-container>

        <ng-container matColumnDef="friendly_name">
          <th mat-header-cell *matHeaderCellDef> Name</th>
          <td mat-cell *matCellDef="let model"> {{model.friendly_name}} </td>
        </ng-container>

        <ng-container matColumnDef="filename">
          <th mat-header-cell *matHeaderCellDef> Filename</th>
          <td mat-cell *matCellDef="let model"> {{model.filename}} </td>
        </ng-container>

        <ng-container matColumnDef="filesize">
          <th mat-header-cell *matHeaderCellDef> Size</th>
          <td mat-cell *matCellDef="let model"> {{model.filesize}} </td>
        </ng-container>

        <ng-container matColumnDef="added_by">
          <th mat-header-cell *matHeaderCellDef> Added By</th>
          <td mat-cell *matCellDef="let model"> {{model.added_by}} </td>
        </ng-container>

        <ng-container matColumnDef="date_added">
          <th mat-header-cell *matHeaderCellDef> Date Added</th>
          <td mat-cell *matCellDef="let model">
            {{model.date_added | date:'yyyy-MM-dd HH:mm:ss' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions</th>
          <td mat-cell *matCellDef="let element;  let j = index;">
            <button mat-icon-button color="warn" matTooltip="Delete entry"
                    (click)="openDialog('Delete', element)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card>
</form>
