import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";
import {NgxFileDropEntry} from "ngx-file-drop";
import {ConfigService} from "../../../../../core/_services/config-service";


@Component({
  selector: 'app-run-data-dialog',
  templateUrl: './add-run-data-dialog.html',
})
export class AddRunDataDialog implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  private uploadedFile = {};
  runDataTypes = [];

  addRunDataForm: FormGroup;

  @Output() onSuccess: any;
  @Output() onError: any;

  constructor(private fb: FormBuilder,
              private configService: ConfigService,
              private dialogRef: MatDialogRef<AddRunDataDialog>) {
  }

  ngOnInit(): void {
    this.addRunDataForm = this.fb.group(({
      runDataType: ['', [Validators.required]]
    }))

    this.fetchRunDataTypes();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private fetchRunDataTypes() {
    this.subscriptions.add(
      this.configService.getConfig('runDataTypes').subscribe(data => {
        data.value.split(',').forEach((type, index) => {
            this.runDataTypes.push({id: index, value: type})
          }
        );
      })
    );
  }

  public onFileDrop(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.uploadedFile =
            {
              filepath: droppedFile.relativePath,
              file: file
            }
        });
      }
    }
  }

  public addFile() {
    console.log('file: ' + this.uploadedFile);

    this.dialogRef.close({
      file: this.uploadedFile['file'],
      filepath: this.uploadedFile['filepath'],
      runDataType: this.addRunDataForm.controls['runDataType'].value,
      filesize: this.uploadedFile['file'].size.toString()
    });
  }
}


