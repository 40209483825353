import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {JobService} from "../../../core/_services/job-service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-job',
  templateUrl: './new-job.component.html',
  styleUrls: ['./new-job.component.scss']
})
export class NewJobComponent implements OnInit {

  private subscriptions = new Subscription();
  newJobForm: FormGroup;

  constructor(private fb: FormBuilder,
              private jobService: JobService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.newJobForm = this.fb.group({
      friendly_name: ['', [Validators.required]]
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createJob() {
    this.subscriptions.add(
      this.jobService.createJob(this.newJobForm.controls["friendly_name"].value)
      .subscribe(data => {
        console.log('new job : ' + JSON.stringify(data));
        this.router.navigate(["/private/job/" + data.id + "/configure"]);
      })
    );
  }

}

