<mat-sidenav-container>


  <mat-sidenav #sidenav mode="side" opened class="octopus-sidenav">
    <a class="mat-h3" routerLink="/private/job/{{jobId}}/configure">
      <fa-icon [icon]="['fas', 'gear']" [fixedWidth]="true"></fa-icon>Job Settings
    </a>
    <br />

    <a class="mat-h3" routerLink="/private/job/{{jobId}}/run">
      <fa-icon [icon]="['fas', 'person-running']" [fixedWidth]="true"></fa-icon>Run Job
    </a>
  </mat-sidenav>

  <mat-sidenav-content class="octopus-sidenav-content">
    <form>
      <mat-card class="octopus-card octopus-main-card">
        <mat-card-title>Job {{job?.friendly_name}}</mat-card-title>

        <mat-card class="octopus-card">
          <mat-card-title>Job Details</mat-card-title>
          Name: {{job?.friendly_name}} <br />
          Status: {{job?.status}} <br />
          Job Date Created: {{job?.date_created | date:'yyyy-MM-dd HH:mm:ss'}} <br />
          Created By: {{job?.created_by}} <br />
          Period: {{job?.parameters["period"]}} <br />
          Job Data Files: <br />

          <label *ngFor="let data of jobData">
            {{data.filename}}
          </label>

        </mat-card>


        <mat-card class="octopus-card">
          <mat-card-title>Runs</mat-card-title>

          <div class="table-responsive mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Id</th>
                <td mat-cell *matCellDef="let run">
                  <a routerLink="/private/job/{{run.job_id}}/run/{{run.id}}">{{run.id}}
                  </a>
                </td>
              </ng-container>

              <ng-container matColumnDef="date_created">
                <th mat-header-cell *matHeaderCellDef> Date Created</th>
                <td mat-cell *matCellDef="let run">
                  {{run.date_created | date:'yyyy-MM-dd HH:mm:ss' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="created_by">
                <th mat-header-cell *matHeaderCellDef> Created By</th>
                <td mat-cell *matCellDef="let run">{{run.created_by}}</td>
              </ng-container>


              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status</th>
                <td mat-cell *matCellDef="let run"> {{run.status}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </mat-card>
      </mat-card>
    </form>

  </mat-sidenav-content>

</mat-sidenav-container>


