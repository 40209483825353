import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {BehaviorSubject, Observable} from "rxjs";
import {Job, JobData} from '../_models/job';
import {Run, RunData} from '../_models/run';


const headers = new HttpHeaders().set('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class JobService {

  private url = environment.apiUrl + '/jobs';

  private runId$ = new BehaviorSubject(null);
  currentRunId = this.runId$.asObservable();

  updateRunId(runId: number) {
    this.runId$.next(runId);
  }

  constructor(private http: HttpClient) {
  }

  createJob(friendly_name: string, job?: Job): Observable<Job> {
    return this.http.post<Job>(
      this.url + "?friendly_name=" + friendly_name, job,
      {headers}
    );
  }

  updateJob(job: Job): Observable<Job> {
    return this.http.put<Job>(this.url + '/' + job.id, job, {headers})
  }

  getJob(jobId: number): Observable<Job> {
    return this.http.get<Job>(this.url + '/' + jobId, {headers})
  }

  getJobs(): Observable<Job[]> {
    return this.http.get<Job[]>(this.url, {headers});
  }

  deleteJob(job: Job): Observable<Job> {
    return this.http.delete<Job>(this.url + '/' + job.id, {headers});
  }

  uploadJobFile(jobId: number, formData: FormData): Observable<JobData> {
    return this.http.post<JobData>(this.url + '/' + jobId + '/upload', formData);
  }

  getJobData(jobId: number): Observable<JobData[]> {
    return this.http.get<JobData[]>(this.url + '/' + jobId + '/data', {headers});
  }

  getJobDataById(jobId: number, jobDataId: number): Observable<JobData> {
    // todo test:  GET http://localhost:5001/api/v1/jobs/1/data/1
    return this.http.get<JobData>(
      this.url + '/' + jobId + '/data/' + jobDataId,
      {headers}
    );
  }

  downloadJobData(jobId: number, jobDataId: number) {
    // todo test:  GET http://localhost:5001/api/v1/jobs/1/data/1/download
    return this.http.get<JobData[]>(
      this.url + '/' + jobId + '/data/' + jobDataId + '/download',
      {headers}
    );
  }

  deleteJobData(jobId: number, jobDataId: number) {
    // todo create:  DELETE http://localhost:5001/api/v1/jobs/1/data/1
    return this.http.delete<JobData[]>(
      this.url + '/' + jobId + '/data/' + jobDataId,
      {headers}
    );
  }


  createRun(jobId: number, run?: Run): Observable<Run> {
    return this.http.post<Run>(this.getRunUrl(jobId), run, {headers});
  }

  updateRun(jobId: number, run: Run): Observable<Run> {
    return this.http.put<Run>(this.getRunUrl(jobId) + '/' + run.id, run, {headers})
  }

  getRun(jobId: number, runId: number): Observable<Run> {
    return this.http.get<Run>(this.getRunUrl(jobId) + '/' + runId, {headers})
  }

  getRuns(jobId: number): Observable<Run[]> {
    return this.http.get<Run[]>(this.getRunUrl(jobId), {headers});
  }

  deleteRun(jobId: number, run: Run): Observable<Run> {
    return this.http.delete<Run>(this.getRunUrl(jobId) + '/' + run.id, {headers});
  }

  uploadRunFile(jobId: number, runId: number, formData: FormData): Observable<RunData> {
    return this.http.post<RunData>(this.getRunUrl(jobId) + '/' + runId + '/upload', formData);
  }

  getRunData(jobId: number, runId: number): Observable<RunData[]> {
    return this.http.get<RunData[]>(this.getRunUrl(jobId) + '/' + runId + '/data', {headers});
  }

  getRunDataById(jobId: number, runId: number, runDataId: number): Observable<RunData> {
    // todo test:  GET http://localhost:5001/api/v1/runs/1/data/1
    return this.http.get<RunData>(
      this.getRunUrl(jobId) + '/' + runId + '/data/' + runDataId,
      {headers}
    );
  }

  downloadRunData(jobId: number, runId: number, runDataId: number) {
    // todo test:  GET http://localhost:5001/api/v1/runs/1/data/1/download
    return this.http.get<RunData[]>(
      this.getRunUrl(jobId) + '/' + runId + '/data/' + runDataId + '/download',
      {headers}
    );
  }

  deleteRunData(jobId: number, runId: number, runDataId: number) {
    // todo create:  DELETE http://localhost:5001/api/v1/runs/1/data/1
    return this.http.delete<RunData[]>(
      this.getRunUrl(jobId) + '/' + runId + '/data/' + runDataId,
      {headers}
    );
  }

  private getRunUrl(jobId: number): string {
    return this.url + '/' + jobId + '/runs';
  }
}
