import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {JobService} from "../../../core/_services/job-service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Job, JOB_STATUS_CREATED, JobData} from "../../../core/_models/job";
import {FormBuilder, FormGroup} from '@angular/forms';
import {ConfigService} from 'src/app/core/_services/config-service';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {AddJobDataDialog} from "./add-job-data-dialog";
import {STATUS_NEW} from "../../../core/_models/const";

@Component({
  selector: 'app-job-settings',
  templateUrl: './job-settings.component.html',
  styleUrls: ['./job-settings.component.scss']
})
export class JobSettingsComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  jobId: number;
  job: Job;

  dataSource = new MatTableDataSource<JobData>();
  displayedColumns: string[] = [
    'filename', 'jobdatatype', 'filesize', 'actions'
  ];
  jobSettingsForm: FormGroup;
  uploadedDataFiles = [];

  @Output() onSuccess: any;
  @Output() onError: any;

  periods = [];

  constructor(private fb: FormBuilder,
              private configService: ConfigService,
              private jobService: JobService,
              private route: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog) {
  }


  ngOnInit(): void {
    this.jobSettingsForm = this.fb.group({
      period: ['']
    });

    this.fetchJobId();
    this.fetchJob()
    this.fetchPeriods();
    this.fetchJobData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private fetchJobId() {
    this.subscriptions.add(
      this.route.params.subscribe(p => {
        this.jobId = p['job_id'];
      })
    );
  }

  private fetchJob() {
    this.subscriptions.add(
      this.jobService.getJob(this.jobId).subscribe(res => {
        this.job = res;
        if (this.job.parameters !== null && this.job.parameters["period"]) {
          this.jobSettingsForm.controls["period"].setValue(this.job.parameters['period']);
        }
      })
    );
  }


  private fetchPeriods() {
    this.subscriptions.add(
      this.configService.getConfig('periods').subscribe(data => {
        data.value.split(',').forEach((type, index) => {
            this.periods.push({id: index, value: type})
          }
        );
      })
    );
  }

  private fetchJobData() {
    this.subscriptions.add(
      this.jobService.getJobData(this.jobId).subscribe(data => {
        this.dataSource.data = data;
      })
    );
  }

  openDialog(action, obj) {
    obj.action = action;

    if (obj.action == 'Delete') {
      console.log('delete dialog here');
      return;
    }
  }

  addDataFile() {
    const dialogRef = this.dialog.open(AddJobDataDialog);
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const formData = new FormData();

          formData.append('file', result.file, result.filepath);
          formData.append('jobdatatype', result.jobDataType);
          formData.append('filesize', result.filesize);

          this.subscriptions.add(
            this.jobService.uploadJobFile(this.jobId, formData).subscribe(res => {
              console.log('upload file response :' + JSON.stringify(res));
              this.uploadedDataFiles.push(result);
              this.fetchJobData();
            }));
        }
      }));
  }

  updateJob() {
    this.job.parameters = {"period": this.jobSettingsForm.controls['period'].value}

    if (this.job.status == STATUS_NEW) {
      this.job.status = JOB_STATUS_CREATED
    }

    this.subscriptions.add(
      this.jobService.updateJob(this.job).subscribe(res => {
        console.log('job updated: ' + JSON.stringify(res));
        this.router.navigate(["/private/job/" + res.id]);
      })
    );
  }
}
