import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {NgxFileDropEntry} from "ngx-file-drop";
import {ModelService} from "../../core/_services/model-service";
import {MatDialogRef} from "@angular/material/dialog";
import {MODEL_TYPE_ETL} from "../../core/_models/model";


@Component({
  selector: 'app-model-file-dialog',
  templateUrl: './add-model-file-dialog.html',
})
export class AddModelFileDialog implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  private uploadedFile = {}

  addModelForm: FormGroup;


  @Output() onSuccess: any;
  @Output() onError: any;

  constructor(private fb: FormBuilder,
              private modelService: ModelService,
              private dialogRef: MatDialogRef<AddModelFileDialog>) {
  }

  ngOnInit(): void {
    this.addModelForm = this.fb.group(({
      friendly_name: ['', [Validators.required]]
    }))
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onFileDrop(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.uploadedFile = {
            "filepath": droppedFile.relativePath,
            "file": file
          }
        });
      }
    }
  }

  public addFile() {
    console.log('file: ' + this.uploadedFile);

    const filepath = this.uploadedFile['filepath'];
    const file = this.uploadedFile['file'];
    const dataValue = this.addModelForm.getRawValue();
    const formData = new FormData()

    formData.append('file', file, filepath);
    formData.append('friendly_name', dataValue.friendly_name);
    formData.append('filesize', file.size.toString());
    formData.append('modeltype', MODEL_TYPE_ETL);

    this.subscriptions.add(
      this.modelService.createModel(formData).subscribe(uploadFileResponse => {
        console.log('upload file response :' + JSON.stringify(uploadFileResponse));
        this.dialogRef.close();
        this.addModelForm.controls['friendly_name'].reset();
      }));
  }


}
