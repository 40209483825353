
export const JOB_STATUS_CREATED = "Created"


export interface Job {
  id: number;
  friendly_name: string;
  date_created: string;
  created_by: number;
  status: string;
  parameters: object;
}

export interface JobData {
  id: number;
  job_id: number;
  jobdatatype: string;
  filename: string;
  filesize: number;
  data_added: string;
  added_by: number;
}

