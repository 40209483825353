<form [formGroup]="processRunForm" name="processRunForm">

  <h2>Process Run</h2>

  <mat-card class="octopus-card">
    <mat-card-subtitle>Select Model</mat-card-subtitle>

    <mat-form-field appearance="fill">
      <mat-label>Select Model to Execute</mat-label>
      <mat-select formControlName="executionModel">
        <mat-option *ngFor="let model of executionModels" [value]="model.id">
          {{model.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card>


  <button mat-raised-button (click)="executeModel()" color="primary">
    EXECUTE
  </button>

  <mat-card class="octopus-card">
    <mat-card-subtitle>View Results</mat-card-subtitle>

    <div class="table-responsive mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="filename">
          <th mat-header-cell *matHeaderCellDef> Filename</th>
          <td mat-cell *matCellDef="let result">
            <a [routerLink]="[]" (click)="downloadFile(result)">
              {{result.filename}}
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="filesize">
          <th mat-header-cell *matHeaderCellDef> Size</th>
          <td mat-cell *matCellDef="let result"> {{result.filesize}} </td>
        </ng-container>

        <ng-container matColumnDef="date_created">
          <th mat-header-cell *matHeaderCellDef> Date Created</th>
          <td mat-cell *matCellDef="let result">
            {{result.date_created | date:'yyyy-MM-dd HH:mm:ss' }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </mat-card>

  <button mat-raised-button (click)="checkForResults()" color="primary">
    CHECK FOR RESULTS
  </button>

</form>
