import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {Job} from "../../core/_models/job";
import {FormBuilder} from "@angular/forms";
import {JobService} from "../../core/_services/job-service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  dataSource = new MatTableDataSource<Job>();
  displayedColumns: string[] = ['friendly_name', 'date_created', 'created_by', 'status'];

  constructor(private fb: FormBuilder,
              private jobService: JobService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.fetchJobs();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchJobs() {
    this.subscriptions.add(
      this.jobService.getJobs().subscribe(data => {
        console.log('Found ' + data.length + ' jobs.')
        this.dataSource.data = data;
      }));
  }

}
