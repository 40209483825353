import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from "rxjs";
import {Config} from '../_models/config';


const headers = new HttpHeaders().set('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private url = environment.apiUrl + '/config';

  constructor(
    private http: HttpClient) {
  }

  getConfigs(): Observable<Config[]> {
    return this.http.get<Config[]>(this.url, {headers});
  }

  getConfig(config_key: string): Observable<Config> {
    return this.http.get<Config>(this.url + '/' + config_key, {headers});
  }

}
