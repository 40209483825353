import {Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {Job, JobData} from "../../../../core/_models/job";
import {ActivatedRoute, Params} from "@angular/router";
import {JobService} from "../../../../core/_services/job-service";
import {Run} from "../../../../core/_models/run";

@Component({
  selector: 'app-run-details',
  templateUrl: './run-details.component.html',
  styleUrls: ['./run-details.component.scss']
})
export class RunDetailsComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  @Input() jobId: number;
  job: Job;
  jobData: JobData[];
  @Output() runId: number;
  run: Run;

  constructor(private jobService: JobService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.jobId = params['job_id'];
      this.runId = params['run_id'];
      if (this.runId) {
        this.fetchRun();
      }
      this.fetchJob();
      this.fetchJobData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchRun() {
    this.subscriptions.add(
      this.jobService.getRun(this.jobId, this.runId).subscribe(res => {
        this.run = res;
      })
    );
  }

  fetchJob() {
    this.subscriptions.add(
      this.jobService.getJob(this.jobId).subscribe(res => {
        this.job = res;
      })
    )
  }

  fetchJobData() {
    this.subscriptions.add(
      this.jobService.getJobData(this.jobId).subscribe(res => {
        this.jobData = res;
      })
    )
  }

}
