<form [formGroup]="jobSettingsForm" name="addJobForm" novalidate>
  <mat-card class="octopus-card octopus-main-card">
    <mat-card-title>
      Configure <a routerLink="/private/job/{{jobId}}">{{job?.friendly_name}}</a> Job
    </mat-card-title>

    <button mat-raised-button color="primary" (click)="addDataFile()"
            class="octopus-card-anchor-button">
      ADD DATA FILE
    </button>

    <mat-card class="octopus-card">
      <mat-card-subtitle>Data Files</mat-card-subtitle>

      <div class="table-responsive mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="filename">
            <th mat-header-cell *matHeaderCellDef> Filename</th>
            <td mat-cell *matCellDef="let jobData"> {{jobData.filename}} </td>
          </ng-container>

          <ng-container matColumnDef="jobdatatype">
            <th mat-header-cell *matHeaderCellDef> Type</th>
            <td mat-cell *matCellDef="let jobData"> {{jobData.jobdatatype}} </td>
          </ng-container>

          <ng-container matColumnDef="filesize">
            <th mat-header-cell *matHeaderCellDef> Size</th>
            <td mat-cell *matCellDef="let jobData"> {{jobData.filesize}} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions</th>
            <td mat-cell *matCellDef="let element;  let j = index;">
              <button mat-icon-button color="warn" matTooltip="Delete Job"
                      (click)="openDialog('Delete', element)">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </mat-card>


    <mat-card class="octopus-card">
      <mat-card-subtitle>Period</mat-card-subtitle>

      <mat-form-field appearance="fill">
        <mat-label>Select Period</mat-label>
        <mat-select formControlName="period" required>
          <mat-option *ngFor="let period of periods" [value]="period.value">
            {{period.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card>

    <button mat-raised-button (click)="updateJob()" color="primary">
      SAVE
    </button>

  </mat-card>


</form>
