import {AfterViewInit, Component, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from '@angular/material/table';
import {Run, RunData} from 'src/app/core/_models/run';
import {STATUS_CREATED, STATUS_NEW} from 'src/app/core/_models/const';
import {ConfigService} from 'src/app/core/_services/config-service';
import {AddRunDataDialog} from './add-run-data-dialog';
import {JobService} from "../../../../../core/_services/job-service";
import {ActivatedRoute, Params} from '@angular/router';


@Component({
  selector: 'app-setup-run',
  templateUrl: './setup-run.component.html',
  styleUrls: ['./setup-run.component.scss']
})
export class SetupRunComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscriptions = new Subscription();

  dataSource = new MatTableDataSource<RunData>();
  displayedColumns: string[] = [
    'filename', 'rundatatype', 'filesize',  'added_by', 'actions'
  ];
  addRunForm: FormGroup;
  collectors = [];
  uploadedDataFiles = [];

  @Output() onSuccess: any;
  @Output() onError: any;

  @Input() jobId: number;

  runId: number;
  run: Run;
  create: string = "CREATE";
  update: string = "UPDATE";

  transformationFileTypes: object[] = [];

  constructor(private fb: FormBuilder,
              private configService: ConfigService,
              private jobService: JobService,
              private route: ActivatedRoute,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.jobService.currentRunId.subscribe(id => this.runId = id);

    this.addRunForm = this.fb.group({
      collectors: new FormArray([]),
      transformationFileType: ['']
    });

    this.route.params.subscribe((params: Params) => {
      this.jobId = params['job_id'];
      this.runId = params['run_id'];

      this.fetchCollectors();
      this.fetchTransformationFileTypes();
      this.setRunInfo();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // todo: OCT-31:
  ngAfterViewInit() {
    this.setRunInfo();
  }

  get collectorsFormArray() {
    return this.addRunForm.controls['collectors'] as FormArray;
  }

  private setRunInfo() {
    if (this.runId) {
      this.jobService.updateRunId(this.runId);
      this.fetchRun();
      this.fetchRunData();
    }
  }

  private fetchCollectors() {
    this.subscriptions.add(
      this.configService.getConfig('collectors').subscribe(data => {
        data.value.split(',').forEach((collector, index) => {
            let items = {id: index, name: collector};
            this.collectors.push(items);
            this.collectorsFormArray.push(
              this.fb.group({
                id: index,
                name: collector,
                isActive: false
              })
            );
          }
        );
      })
    );
  }

  private fetchTransformationFileTypes() {
    this.subscriptions.add(
      this.configService.getConfig('transformationFileTypes').subscribe(data => {
        data.value.split(',').forEach((type, index) => {
            this.transformationFileTypes.push({id: index, value: type})
          }
        );
      })
    );
  }

  private fetchRunData() {
    if (this.runId) {
      this.jobService.getRunData(this.jobId, this.runId).subscribe(data => {
        console.log('Fetched run data for job ' + this.jobId + ' and run ' + this.runId);
        this.dataSource.data = data;
      })
    }
  }

  createRun() {
    this.run.parameters = {
      "collectors": this.extractSelectedCollectors(),
      "transformationFileType": this.addRunForm.controls['transformationFileType'].value
    }
    this.run.status = STATUS_CREATED;
    this.updateRun();
  }

  private extractSelectedCollectors() {
    return this.addRunForm.value['collectors']
    .map((row) => {
      if (row["isActive"]) {
        return row["name"];
      }
    })
    .filter(x => {
      return x;
    });
  }

  private fetchRun() {
    this.subscriptions.add(
      this.jobService.getRun(this.jobId, this.runId).subscribe(res => {
        this.run = res;
        this.setRunValues();
      })
    );
  }

  private setRunValues() {
    if (this.run.parameters !== null && this.run.parameters["collectors"]) {
      const persistedCollectors = this.run.parameters["collectors"];
      const collect = [];

      this.collectorsFormArray.value.forEach(c => {
        collect.push(c);
        persistedCollectors.find(f => {
          if (f == c["name"]) {
            c["isActive"] = true;
          }
        });
      });

      this.addRunForm.get("collectors").patchValue(collect);
    }

    if (this.run.parameters != null && this.run.parameters['transformationFileType']) {
      this.addRunForm.controls["transformationFileType"].setValue(
        this.run.parameters['transformationFileType']
      );
    }
  }

  openDialog(action, obj) {
    obj.action = action;

    if (obj.action == 'Delete') {
      console.log('delete dialog here');
      return;
    }
  }

  addDataFile() {
    const dialogRef = this.dialog.open(AddRunDataDialog);
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const formData = new FormData();

          formData.append('file', result.file, result.filepath);
          formData.append('rundatatype', result.runDataType);
          formData.append('filesize', result.filesize);

          if (!this.runId) {
            this.subscriptions.add(
              this.jobService.createRun(this.jobId).subscribe(res => {
                this.runId = res.id;
                this.jobService.updateRunId(this.runId);
                this.uploadRunFile(formData, result);
              }));
          } else {
            this.uploadRunFile(formData, result);
          }
        }
      }));
  }

  private uploadRunFile(formData, result) {
    this.subscriptions.add(
      this.jobService.uploadRunFile(this.jobId, this.runId, formData)
      .subscribe(res => {
        console.log('upload file response :' + JSON.stringify(res));
        this.uploadedDataFiles.push(result);
        this.fetchRunData();
        this.fetchRun();
      }));
  }

  actionRun(action: string) {
    if (action == this.create) {
      this.createRun();
      return;
    }

    if (action == this.update) {
      this.updateRun();
      return;
    }
  }

  updateRun() {
    this.run.parameters = {
      "collectors": this.extractSelectedCollectors(),
      "transformationFileType": this.addRunForm.controls['transformationFileType'].value
    }

    if (this.run.status == STATUS_NEW) {
      this.run.status = STATUS_CREATED;
    }

    this.subscriptions.add(
      this.jobService.updateRun(this.jobId, this.run).subscribe(res => {
        console.log('run updated: ' + JSON.stringify(res));
      })
    );
  }

  isCreateDisabled(): boolean {
    return !this.run;
  }

  fetchRunStatus(): string {
    if (!this.run) {
      return this.create;
    }

    if (this.run.status == 'New') {
      return this.create;
    }

    if (this.run.status != 'New') {
      return this.update;
    }

    return "no";
  }
}
